import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import coreXFutureImage from '../images/CoreXFutureText.png';
import backgroundImage from "../images/Background.png";
import girisButtonImage from '../images/GirisButton.png';
import textAreaBackground from "../images/UsernamePassBackground.png"
import styles from './IntroPages.module.css';

function LoginPage({ navigate }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [opacity, setOpacity] = useState(1);
  // const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('jwt');
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    setOpacity(0.3);

    try {
      const response = await fetch('https://corexas-analysis-backend.onrender.com/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      console.log(data.token);

      if (response.ok) {
        localStorage.setItem('jwt', data.token);
        navigate('/companytypeselect');
        setOpacity(1);
      } else {
        alert(data.message || 'Giriş başarısız.');
        setOpacity(1);
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Sunucuya bağlanırken hata oluştu.');
      setOpacity(1);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: 1,
          transition: "opacity 0.5s ease-in-out",
          zIndex: 1,
        }}
      />
      <div className={styles.intropage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", fontFamily: "Bookman Old Style", opacity: opacity, transition: "opacity 0.5s ease-in-out", position: "relative", zIndex: 2 }}>
        <div className={styles.content}>
          <img src={coreXFutureImage} alt="CoreXFuture" style={{ position: "absolute", top: "5vh", left: "4vw", height: "25vh", alignSelf: "center" }} />
          <div className={styles.leftside}>
            <div style={{ width: "100%", paddingTop: "10vh" }}>
              <textarea
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                style={{
                  backgroundImage: `url(${textAreaBackground})`,
                  backgroundColor: "transparent",
                  width: "60%",
                  minWidth: "300px",
                  backgroundSize: "100% auto",
                  backgroundRepeat: "no-repeat",
                  border: "0px solid",
                  padding: "10px",
                  fontSize: "35px",
                  resize: "none",
                  backgroundPosition: "center",
                  marginBottom: "20px",
                  color: "white",
                  textAlign: "center",
                  aspectRatio: 10,
                  alignContent: "center",
                  fontFamily: "Bookman Old Style",
                  scrollbarWidth: 0,
                  overflow: "hidden"
                }}
                placeholder='Kullanıcı Adı'
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{
                  backgroundImage: `url(${textAreaBackground})`,
                  backgroundColor: "transparent",
                  width: "60%",
                  minWidth: "300px",
                  backgroundSize: "100% auto",
                  backgroundRepeat: "no-repeat",
                  border: "0px solid",
                  padding: "10px",
                  fontSize: "35px",
                  backgroundPosition: "center",
                  color: "white",
                  textAlign: "center",
                  aspectRatio: 10,
                  alignContent: "center",
                  fontFamily: "Bookman Old Style",
                  marginTop: "0px",
                }}
                placeholder="Şifre"
              />
            </div>
          </div>
          <div className={styles.rightside}>
            <div className={styles.buttoncontainer}>
              <img src={girisButtonImage} alt="Başla" className={styles.startbutton} onClick={handleLogin} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;