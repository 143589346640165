import React from 'react';
import coreXFutureImage from '../images/CoreXFutureText.png';
import backgroundImage from "../images/Background.png";
import yeniFirmaButtonImage from '../images/YeniFirmaButton.png';
import kayitliFirmaButtonImage from '../images/KayitliFirmaButton.png';
import styles from './IntroPages.module.css';

function CompanyTypeSelectPage({ navigate }) {
  return (
    <div className={styles.intropage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", fontFamily: "Bookman Old Style", flexDirection: "column" }}>
      <img src={coreXFutureImage} alt="CoreXFuture" style={{ marginTop: "5vh", height: "25vh", alignSelf: "center" }} />
      <div className={styles.content} style={{ height: "50vh" }}>
        <div className={styles.leftside} style={{ height: "auto", justifyContent: "normal" }}>
          <div className={styles.buttoncontainer} style={{ display: "flex", justifyContent: "end", marginRight: "3vw" }}>
            <img src={kayitliFirmaButtonImage} alt="Başla" className={styles.startbutton} onClick={() => { navigate("/entercompanyname/saved"); }} />
          </div>
        </div>
        <div className={styles.leftside} style={{ height: "auto", justifyContent: "normal" }}>
          <div className={styles.buttoncontainer} style={{ display: "flex", justifyContent: "start", marginLeft: "3vw" }} >
            <img src={yeniFirmaButtonImage} alt="Başla" className={styles.startbutton} onClick={() => { navigate("/entercompanyname/new"); }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyTypeSelectPage;