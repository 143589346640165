import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import HomePage from './components/HomePage';
import QuizPage from './components/QuizPage';
import ResultsDashboard from './components/ResultsDashboard';
import LoginPage from './components/LoginPage';
import IntroInfoPage from './components/IntroInfoPage';
import CompanyTypeSelectPage from './components/CompanyTypeSelectPage';
import EnterCompanyNamePage from './components/EnterCompanyNamePage';
import QuizStarterPage from './components/QuizStartingPage';
import TransitionWrapper from './components/TransitionWrapper';
import QuizFinishedPage from './components/QuizFinishedPage';
import SelectQuizPage from './components/SelectQuizPage';

function App() {
  const isAuthenticated = () => {
    return !!localStorage.getItem('jwt');
  };

  const ProtectedRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/" />;
  };

  return (
    <Router>
      <TransitionWrapper>
        {({ navigate }) => (
          <Routes>
            <Route path='/' element={<LoginPage navigate={navigate} />} />
            <Route path="/companytypeselect" element={<ProtectedRoute><CompanyTypeSelectPage navigate={navigate} /></ProtectedRoute>} />
            <Route path="/entercompanyname/:status" element={<ProtectedRoute><EnterCompanyNamePage navigate={navigate} /></ProtectedRoute>} />
            {/* <Route path="/home" element={<ProtectedRoute><HomePage navigate={navigate} /></ProtectedRoute>} /> */}
            <Route path="/intro" element={<ProtectedRoute><IntroInfoPage navigate={navigate} /></ProtectedRoute>} />
            <Route path="/selectquiz" element={<ProtectedRoute><SelectQuizPage navigate={navigate} /></ProtectedRoute>} />
            <Route path="/quizstart/:quizType" element={<ProtectedRoute><QuizStarterPage navigate={navigate} /></ProtectedRoute>} />
            <Route path="/quiz/:quizType/:quizFile/:sectionName" element={<ProtectedRoute><QuizPage navigate={navigate} /></ProtectedRoute>} />
            <Route path="/quizfinished/:quizType" element={<ProtectedRoute><QuizFinishedPage navigate={navigate} /></ProtectedRoute>} />
            <Route path="/results" element={<ProtectedRoute><ResultsDashboard navigate={navigate} /></ProtectedRoute>} />
          </Routes>
        )}
      </TransitionWrapper>
    </Router>
  );
}

export default App;