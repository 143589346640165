import React, { useState, useEffect } from 'react';
// import { utils, writeFileXLSX } from 'xlsx';
import './ResultsDashboard.css'; // Optional for additional styling

import {useUnsafeChatAdapter} from '@nlux/openai-react';
import {AiChat} from '@nlux/react';
import '@nlux/themes/nova.css';

function ResultsDashboard({ navigate }) {
  const [results, setResults] = useState([]);

  const adapter = useUnsafeChatAdapter({
    apiKey: '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',
    model: 'gpt-4o',
    dataTransferMode: 'batch',
    systemMessage: 'Given the company results below: \n' + JSON.stringify(results) + "\n Answer the questions. You are Corexas Consulting Group company assistant. "
  });

  useEffect(() => {
    const savedAnswers = localStorage.getItem('quizAnswers');
    const savedTargetValues = localStorage.getItem('targetValues');

    console.log(savedAnswers);
    console.log(savedTargetValues);

    if (savedAnswers || savedTargetValues) {
      const parsedAnswers = JSON.parse(savedAnswers);
      const parsedTargetValues = JSON.parse(savedTargetValues);

      const combinedList = { ...parsedAnswers, ...parsedTargetValues}

      const resultData = Object.keys(combinedList).map((question) => ({
        question,
        answer: parsedAnswers[question] || 30,
        target: parsedTargetValues[question] * 10 || 'N/A',
      }));

      setResults(resultData);
      
      // const worksheet = utils.json_to_sheet(resultData);
      // const workbook = utils.book_new();
      // utils.book_append_sheet(workbook, worksheet, "Quiz Results");
      // writeFileXLSX(workbook, 'quiz-results.xlsx');

    } else {
      // Redirect to home if no results are found
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="results-dashboard">
      <h1>Quiz Results Dashboard</h1>
      <table className="results-table">
        <thead>
          <tr>
            <th>Question</th>
            <th>Your Answer</th>
            <th>Target Value</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td>{result.question}</td>
              <td>{result.answer}</td>
              <td>{result.target}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <AiChat adapter={adapter} />
    </div>
  );
}

export default ResultsDashboard;
