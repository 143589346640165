import React from 'react';
import backgroundImage from "../images/Background.png";
// import kaydetButtonImage from '../images/KaydetButton.png';
// import devamEtButtonImage from '../images/DevamEtButton.png';
import styles from './IntroPages.module.css';
// import { useParams } from 'react-router-dom';

function SelectQuizPage({ navigate }) {
    // const { quizType } = useParams();
    // const [quizName, setQuizName] = useState("");

    // useEffect(() => {
    //     switch (quizType) {
    //         case "future":
    //             setQuizName("Future");
    //             break;
    //         case "digitalization":
    //             setQuizName("Digitalization");
    //             break;
    //         case "sustainability":
    //             setQuizName("Sustainability");
    //             break;
    //         case "startup":
    //             setQuizName("Startup");
    //             break;
    //         default:
    //             break;
    //     }
    // }, [quizType])


    return (
        <div className={styles.intropage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", fontFamily: "Bookman Old Style", flexDirection: "column" }}>
            <div className={styles.content} style={{}}>
                <div className={styles.leftside} style={{ paddingTop: "5vh", flexDirection: "row", display: "flex", justifyItems: "center" }}>
                    {/* <img src={coreXTextImage} alt="CoreXFuture" style={{ height: "25vh", alignSelf: "center" }} /> */}
                    <h2 style={{ fontFamily: "Bookman Old Style", fontSize: "6vh" }}>Choose a quiz to continue/start with</h2>
                </div>
                <div className={styles.rightside} style={{}}>
                    <div className={styles.buttoncontainer} style={{}} >
                        {/* <img
                            src={kaydetButtonImage}
                            alt="Başla"
                            className={styles.startbutton}
                            onClick={() => navigate('/results')}
                        />
                        <img
                            src={devamEtButtonImage}
                            alt="Devam Et"
                            className={styles.startbutton}
                            // onClick={handleBasla}
                        /> */}
                        <button onClick={() => navigate('/quiz/future/ProblemAnaBoyut.json/PROBLEM')}>Innovation and R&D Analysis</button>
                        <button onClick={() => navigate('/quiz/digitalization/OperasyonAnaBoyut.json/OPERASYON')}>Digitalization and Technology Maturity Analysis</button>
                        <button onClick={() => navigate('/quiz/sustainability/OperasyonAnaBoyut.json/OPERASYON')}>Sustainability Maturity Analysis</button>
                        <button onClick={() => navigate('/quiz/startup/ProblemAnaBoyut.json/PROBLEM')}>Startup Maturity Analysis</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectQuizPage;