import React from 'react';
import styles from './IntroPages.module.css';
import baslaButtonImage from '../images/BaslaButton.png';
import coreXFutureImage from '../images/CoreXFutureText.png';
import backgroundImage from "../images/Background.png";
// import { useNavigate } from 'react-router-dom';

function IntroInfoPage({ navigate }) {
  // const navigate = useNavigate();

  // const handleStart = async () => {
  //   const companyName = localStorage.getItem('companyName');
  //   if (!companyName) {
  //     alert('No company name found. Redirecting to login.');
  //     navigate('/intro');
  //     return;
  //   }

  //   try {
  //     const response = await fetch(`https://corexas-analysis-backend.onrender.com/api/progress/${encodeURIComponent(companyName)}`, {
  //       method: 'GET',
  //       headers: {
  //         Authorization: localStorage.getItem('jwt'),
  //       },
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data.latestVisitedRoute) {
  //         navigate(data.latestVisitedRoute);
  //       } else {
  //         navigate('/quizstart/innovation');
  //       }
  //     } else {
  //       if (response.status === 404) {
  //         alert('Company not found. Redirecting to login.');
  //         navigate('/intro');
  //       } else if (response.status === 401) {
  //         alert('Unauthorized. Redirecting to login.');
  //         navigate('/');
  //       } else {
  //         alert('Failed to fetch progress.');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching progress:', error);
  //     alert('Error fetching progress. Please try again later.');
  //   }
  // };

  return (
    <div className={styles.intropage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
      <div className={styles.content}>
        <div className={styles.leftside}>
          <img src={coreXFutureImage} alt="CoreXFuture" className={styles.corexfutureimage} />
          <h2 className={styles.subtitle}>Kurumsal Gelecek Olgunluk Değerlendirme Sistemine Hoşgeldiniz!</h2>
          <p className={styles.description}>
            Bu sistem, geliştirmiş olduğumuz özel bir model ile kurumunuzu uçtan uca analiz ederek şu anki durumunu ve
            geleceğe ne kadar hazır olduğunu derinlemesine analiz etmekte, bununla birlikte güçlü ve zayıf
            alanlarınızı, ihtiyaçlarınızı, iyileşme ve gelişim alanlarınızı ve en önemlisi gelecek için keşif
            alanlarınızı ortaya çıkarmaktadır.
          </p>
        </div>
        <div className={styles.rightside}>
          <div className={styles.buttoncontainer}>
            <img src={baslaButtonImage} alt="Başla" className={styles.startbutton} onClick={() => { navigate("/selectquiz"); }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroInfoPage;
