import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function TransitionWrapper({ children }) {
  const [opacity, setOpacity] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    setOpacity(0);
    setTimeout(() => {
      navigate(path);
      setOpacity(1);
    }, 500);
  };

  useEffect(() => {
    const fadeInTimeout = setTimeout(() => setOpacity(1), 50);
    return () => clearTimeout(fadeInTimeout);
  }, [location.pathname]);

  useEffect(() => {
    const scaleContent = () => {
      const wrapper = document.getElementById('scale-wrapper');

      // Calculate the scale factors
      const scaleX = window.innerWidth / wrapper.offsetWidth;
      const scaleY = window.innerHeight / wrapper.offsetHeight;

      // Use the smaller scale factor to maintain aspect ratio
      const scale = Math.min(scaleX, scaleY);

      // Apply the scaling and center the content
      wrapper.style.transform = `scale(${scale})`;
      wrapper.style.left = `${(window.innerWidth - wrapper.offsetWidth * scale) / 2}px`;
      wrapper.style.top = `${(window.innerHeight - wrapper.offsetHeight * scale) / 2}px`;
    };

    // Scale content on load and resize
    scaleContent();
    window.addEventListener('resize', scaleContent);

    return () => window.removeEventListener('resize', scaleContent);
  }, []);

  return (
    <div
      id="scale-wrapper"
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        transformOrigin: "top left",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: 1,
          transition: "opacity 0.5s ease-in-out",
          zIndex: 1,
        }}
      />

      <div
        style={{
          opacity: opacity,
          transition: "opacity 0.5s ease-in-out",
          position: "relative",
          zIndex: 2,
        }}
      >
        {children({ navigate: handleNavigation })}
      </div>
    </div>
  );
}

export default TransitionWrapper;
