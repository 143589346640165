import React, { useState } from 'react';
import coreXFutureImage from '../images/CoreXFutureText.png';
import backgroundImage from "../images/Background.png";
import devamEtButtonImage from '../images/DevamEtButton.png';
import kaydetButtonImage from '../images/KaydetButton.png';
import styles from './IntroPages.module.css';
import textAreaBackground from "../images/UsernamePassBackground.png"
import { useParams } from 'react-router-dom';

function EnterCompanyNamePage({ navigate }) {
  const { status } = useParams();
  // const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFindCompany = async () => {
    try {
      if (!companyName.trim()) {
        setErrorMessage('Firma adı boş olamaz.');
        return;
      }

      const simplifiedName = companyName.trim().toLowerCase();

      const response = await fetch(`https://corexas-analysis-backend.onrender.com/api/companies/find?companyName=${encodeURIComponent(simplifiedName)}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('jwt'),
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.company) {
          localStorage.setItem("companyName", simplifiedName);
          navigate('/intro');
        } else {
          setErrorMessage('Firma bulunamadı.');
        }
      } else {
        setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      console.error('Error finding company:', error);
      setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const handleCreateCompany = async () => {
    try {
      if (!companyName.trim()) {
        setErrorMessage('Firma adı boş olamaz.');
        return;
      }

      const simplifiedName = companyName.trim().toLowerCase();

      const response = await fetch(`https://corexas-analysis-backend.onrender.com/api/companies/create`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('jwt'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ companyName: simplifiedName }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.company) {
          localStorage.setItem("companyName", simplifiedName);
          navigate('/intro');
        } else {
          setErrorMessage('Firma oluşturulamadı.');
        }
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      console.error('Error creating company:', error);
      setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const handleSubmit = () => {
    if (status === 'saved') {
      handleFindCompany();
    } else if (status === 'new') {
      handleCreateCompany();
    }
  };

  return (
    <div className={styles.intropage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", fontFamily: "Bookman Old Style", flexDirection: "column" }}>
      <div className={styles.content} style={{}}>
        <img src={coreXFutureImage} alt="CoreXFuture" style={{ position: "absolute", top: "5vh", left: "4vw", height: "25vh", alignSelf: "center" }} />
        <div className={styles.leftside} style={{ paddingTop: "5vh" }}>
          <textarea
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            style={{
              backgroundImage: `url(${textAreaBackground})`,
              backgroundColor: "transparent",
              width: "70%",
              minWidth: "300px",
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
              border: "0px solid",
              padding: "10px",
              fontSize: "35px",
              resize: "none",
              backgroundPosition: "center",
              marginBottom: "20px",
              color: "white",
              textAlign: "center",
              aspectRatio: 10,
              alignContent: "center",
              fontFamily: "Bookman Old Style"
            }}
            placeholder="Firma Adı"
          />
          {errorMessage && (
            <div style={{ color: 'red', marginTop: '10px', fontSize: '18px' }}>
              {errorMessage}
            </div>
          )}
        </div>
        <div className={styles.rightside} style={{}}>
          <div className={styles.buttoncontainer} style={{}} >
            <img
              src={status === "saved" ? devamEtButtonImage : kaydetButtonImage}
              alt="Başla"
              className={styles.startbutton}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterCompanyNamePage;