import React, { useState, useEffect } from 'react';
import coreXTextImage from '../images/CoreXText.png';
import backgroundImage from "../images/Background.png";
import baslaButtonImage from '../images/BaslaButton.png';
import styles from './IntroPages.module.css';
import { useParams } from 'react-router-dom';

function QuizStarterPage({ navigate }) {
    const { quizType } = useParams();
    const [quizName, setQuizName] = useState("");

    const handleBasla = async () => {
        let route = '';
        if (quizType === "innovation") {
            route = '/quiz/future/ProblemAnaBoyut.json/PROBLEM';
        } else if (quizType === "digitalization") {
            route = '/quiz/digitalization/OperasyonAnaBoyut.json/OPERASYON';
        } else if (quizType === "sustainability") {
            route = '/quiz/sustainability/OperasyonAnaBoyut.json/OPERASYON';
        } else if (quizType === "startup") {
            route = '/quiz/startup/ProblemAnaBoyut.json/PROBLEM';
        }
        
        const companyName = localStorage.getItem('companyName');
        try {
            const response = await fetch(`https://corexas-analysis-backend.onrender.com/api/progress/${encodeURIComponent(companyName)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('jwt'),
                },
                body: JSON.stringify({
                    latestVisitedRoute: route,
                }),
            });
            if (!response.ok) {
                console.error('Failed to update progress.');
            }
        } catch (error) {
            console.error('Error updating progress:', error);
        }

        navigate(route);
    };

    useEffect(() => {
        switch (quizType) {
            case "innovation":
                setQuizName("Future");
                break;
            case "digitalization":
                setQuizName("Digitalization");
                break;
            case "sustainability":
                setQuizName("Sustainability");
                break;
            case "startup":
                setQuizName("Startup");
                break;
            default:
                break;
        }
    }, [quizType])
    

    return (
        <div className={styles.intropage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", fontFamily: "Bookman Old Style", flexDirection: "column" }}>
            <div className={styles.content} style={{}}>
                <div className={styles.leftside} style={{ paddingTop: "5vh", flexDirection: "row", display: "flex", justifyItems: "center" }}>
                    <img src={coreXTextImage} alt="CoreXFuture" style={{ height: "25vh", alignSelf: "center" }} />
                    <h2 style={{ fontFamily: "Bookman Old Style", fontSize: "6vh" }}>{quizName}</h2>
                </div>
                <div className={styles.rightside} style={{}}>
                    <div className={styles.buttoncontainer} style={{}} >
                        <img
                            src={baslaButtonImage}
                            alt="Başla"
                            className={styles.startbutton}
                            onClick={handleBasla}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuizStarterPage;