import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import { utils, writeFileXLSX } from 'xlsx';
import "./QuizPage.css";
import backgroundImage from "../images/Background.png";

import leftArrow from "../images/LeftArrow.png"
import rightArrow from "../images/RightArrow.png"

import previousQuestion from "../images/PreviousQuestion.png"
import nextQuestion from "../images/NextQuestion.png"

import previousQuestionHover from "../images/PreviousQuestionHover.png"
import nextQuestionHover from "../images/NextQuestionHover.png"

import XRightWhite from "../images/XSagBeyaz.png"
import XLeftWhite from "../images/XSolBeyaz.png"

function QuizPage({ navigate }) {
  const { quizType, quizFile, sectionName } = useParams();
  const [quizData, setQuizData] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [sliderValue, setSliderValue] = useState(30);
  const [targetValues, setTargetValues] = useState({});
  const [isFirstBigSection, setIsFirstBigSection] = useState();
  const [isLastBigSection, setIsLastBigSection] = useState();
  // const navigate = useNavigate();

  const updateProgress = async (updatedAnswers, updatedTargetValues) => {
    try {
      const sectionNames = {
        "sustainability": ["OPERASYON", "ORGANIZASYON", "STRATEJI", "TEKNOLOJI"],
        "startup": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON", "OPERASYON"],
        "future": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON"],
        "digitalization": ["OPERASYON", "ORGANIZASYON", "STRATEJI", "TEKNOLOJI"]
      };

      const currentQuizSections = sectionNames[quizType];

      const bigSectionIndex = currentQuizSections.indexOf(sectionName);

      const companyName = localStorage.getItem('companyName');
      // const response = await fetch(`http://127.0.0.1:5000/api/progress/${encodeURIComponent(companyName)}`, {
      const response = await fetch(`https://corexas-analysis-backend.onrender.com/api/progress/${encodeURIComponent(companyName)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('jwt'),
        },
        body: JSON.stringify({
          quizType,
          answers: updatedAnswers,
          targetValues: updatedTargetValues,
          currentBigSectionIndex: bigSectionIndex,
          currentSectionIndex,
          currentQuestionIndex,
          latestVisitedRoute: window.location.pathname,
        }),
      }); if (!response.ok) {
        console.error('Failed to update progress.');
      }
    } catch (error) {
      console.error('Error updating progress:', error);
    }
  };

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/assets/quizdata/${quizType}/${quizFile}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setQuizData(data.sections))
      .catch(error => console.error('Error fetching the quiz file:', error));
  }, [quizType, quizFile]);

  useEffect(() => {
    const fetchProgress = async () => {
      if (quizData.length === 0) {
        return;
      }

      const sectionNames = {
        "sustainability": ["OPERASYON", "ORGANİZASYON", "STRATEJİ", "TEKNOLOJİ"],
        "startup": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON", "OPERASYON"],
        "future": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON"],
        "digitalization": ["OPERASYON", "ORGANİZASYON", "STRATEJİ", "TEKNOLOJİ"]
      };

      const currentQuizSections = sectionNames[quizType];
      // console.log("Sec Name: " + sectionName + " and index: " + currentQuizSections.indexOf(sectionName));
      // console.log("cond: " + (data.currentBigSectionIndex !== bigSectionIndex && (data.currentSectionIndex) + "data current sec tindex")
      const bigSectionIndex = currentQuizSections.indexOf(sectionName);

      const companyName = localStorage.getItem('companyName');

      if (!companyName) {
        alert('No company name found. Redirecting to login. Currently intro but will be changed.');
        navigate('/intro');
        return;
      }

      try {
        const response = await fetch(`https://corexas-analysis-backend.onrender.com/api/progress/${encodeURIComponent(companyName)}`, {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('jwt'),
          },
        });

        if (response.ok) {
          const data = await response.json();
          const quizProgressData = data.quizProgress && data.quizProgress[quizType] ? data.quizProgress[quizType] : {};

          setAnswers(quizProgressData.answers || {});
          setTargetValues(quizProgressData.targetValues || {});

          const isSameBigSection = data.quizProgress[quizType]?.currentBigSectionIndex === bigSectionIndex;
          const sectionIndex = isSameBigSection ? data.quizProgress[quizType]?.currentSectionIndex : 0;
          const questionIndex = isSameBigSection ? data.quizProgress[quizType]?.currentQuestionIndex : 0;

          const validSectionIndex = Math.min(Math.max(sectionIndex, 0), quizData.length - 1);
          const sectionQuestions = quizData[validSectionIndex]?.questions || [];
          const validQuestionIndex = Math.min(Math.max(questionIndex, 0), sectionQuestions.length - 1);

          setCurrentSectionIndex(validSectionIndex);
          setCurrentQuestionIndex(validQuestionIndex);
          // setCurrentSectionIndex((data.currentBigSectionIndex === bigSectionIndex && (data.currentSectionIndex)) ? data.currentSectionIndex : 0);
          // setCurrentQuestionIndex((data.currentQuestionIndex && data.currentBigSectionIndex === bigSectionIndex) ? data.currentQuestionIndex : 0);
        } else {
          if (response.status === 404) {
            alert('Company not found. Redirecting to login.');
            navigate('/intro');
          } else if (response.status === 401) {
            alert('Unauthorized. Redirecting to login.');
            navigate('/');
          } else {
            alert('Failed to fetch progress.');
          }
        }
      } catch (error) {
        console.error('Error fetching progress:', error);
        alert('Error fetching progress. Please try again later.');
      }
    }

    fetchProgress();
  }, [navigate, quizType, sectionName, quizData]);

  const currentSection = quizData[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  const handleChange = (value) => {
    // console.log(value + " and calc: " + (value - 10) / 40 * 80 + 10);
    // document.documentElement.style.setProperty('--range-value', (value - 10) / 40 * 80 + 10 + '%');
    const updatedAnswers = {
      ...answers,
      [encodeKey(currentQuestion.question)]: value
    };
    setAnswers(updatedAnswers);
    // setSliderValue(value);

    localStorage.setItem('quizAnswers', JSON.stringify(updatedAnswers));
    updateProgress(updatedAnswers, targetValues);
  };

  useEffect(() => {
    // console.log(sliderValue + " and calc: " + ((sliderValue - 10) / 40 * 80 + 10));
    document.documentElement.style.setProperty('--range-value', (sliderValue - 10) / 40 * 80 + 10 + '%');
    console.log("Current Slider Value:", sliderValue);
  }, [sliderValue])


  const handleTargetChange = (value) => {
    const updatedTargetValues = {
      ...targetValues,
      [encodeKey(currentQuestion?.question)]: value
    };
    setTargetValues(updatedTargetValues);

    localStorage.setItem('targetValues', JSON.stringify(updatedTargetValues));
    updateProgress(answers, updatedTargetValues);
  };


  const handleNext = () => {
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      setCurrentQuestionIndex(prevIndex => {
        const nextIndex = prevIndex + 1;
        // setSliderValue(answers[quizData[currentSectionIndex]?.questions[nextIndex].question] || 30); // Reset slider value
        return nextIndex;
      });
    } else if (currentSectionIndex < quizData.length - 1) {
      setCurrentSectionIndex(prevIndex => {
        const nextSectionIndex = prevIndex + 1;
        setCurrentQuestionIndex(0);
        // setSliderValue(answers[quizData[nextSectionIndex]?.questions[0].question] || 30); // Reset slider value
        return nextSectionIndex;
      });
    }
    updateProgress(answers, targetValues, currentSectionIndex, currentQuestionIndex);
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prevIndex => {
        const prevQuestionIndex = prevIndex - 1;
        setSliderValue(answers[quizData[currentSectionIndex]?.questions[prevQuestionIndex].question] || 30);
        updateProgress(answers, targetValues, currentSectionIndex, currentQuestionIndex);
        return prevQuestionIndex;
      });
    } else if (currentSectionIndex > 0) {
      setCurrentSectionIndex(prevIndex => {
        const prevSectionIndex = prevIndex - 1;
        const lastQuestionIndex = quizData[prevSectionIndex].questions.length - 1;
        setCurrentQuestionIndex(lastQuestionIndex);
        setSliderValue(answers[quizData[prevSectionIndex]?.questions[lastQuestionIndex].question] || 30);
        updateProgress(answers, targetValues, currentSectionIndex, currentQuestionIndex);
        return prevSectionIndex;
      });
    } else { handlePreviousBigSection(); }
  };

  const handleJumpToNextSection = () => {
    if (currentSectionIndex < quizData.length - 1) {
      setCurrentSectionIndex(prevIndex => {
        const nextSectionIndex = prevIndex + 1;
        setCurrentQuestionIndex(0);
        setSliderValue(answers[quizData[nextSectionIndex]?.questions[0].question] || 30); // Reset slider value
        return nextSectionIndex;
      });
    } else {
      handleSave();
    }
  };

  const handleJumpToPreviousSection = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex(prevIndex => {
        const prevSectionIndex = prevIndex - 1;
        setCurrentQuestionIndex(0);
        setSliderValue(answers[quizData[prevSectionIndex]?.questions[0].question] || 30); // Reset slider value
        return prevSectionIndex;
      });
    } else {
      handlePreviousBigSection();
    }
  };

  const handleSave = async () => {
    let route;
    if (quizType === "sustainability") {
      if (sectionName === "OPERASYON")
        route = '/quiz/sustainability/OrganizasyonAnaBoyut.json/ORGANIZASYON';
      else if (sectionName === "ORGANIZASYON")
        route = '/quiz/sustainability/StratejiAnaBoyut.json/STRATEJI';
      else if (sectionName === "STRATEJI")
        route = '/quiz/sustainability/TeknolojiAnaBoyut.json/TEKNOLOJI';
      else if (sectionName === "TEKNOLOJI") {
        route = '/quizfinished/sustainability';
        // route = '/quizstart/startup';
      }
    } else if (quizType === "startup") {
      if (sectionName === "PROBLEM")
        route = '/quiz/startup/IhtiyacAnaBoyut.json/İHTİYAÇ';
      else if (sectionName === "İHTİYAÇ")
        route = '/quiz/startup/FirsatAnaBoyut.json/FIRSAT';
      else if (sectionName === "FIRSAT")
        route = '/quiz/startup/TehditAnaBoyut.json/TEHDİT';
      else if (sectionName === "TEHDİT")
        route = '/quiz/startup/StratejiAnaBoyut.json/STRATEJİ';
      else if (sectionName === "STRATEJİ")
        route = '/quiz/startup/TeknolojiAnaBoyut.json/TEKNOLOJİ';
      else if (sectionName === "TEKNOLOJİ")
        route = '/quiz/startup/OrganizasyonAnaBoyut.json/ORGANİZASYON';
      else if (sectionName === "ORGANİZASYON")
        route = '/quiz/startup/OperasyonAnaBoyut.json/OPERASYON';
      else if (sectionName === "OPERASYON")
        route = '/quizfinished/startup';
      // route = '/results';
    } else if (quizType === "future") {
      if (sectionName === "PROBLEM")
        route = '/quiz/future/IhtiyacAnaBoyut.json/İHTİYAÇ';
      else if (sectionName === "İHTİYAÇ")
        route = '/quiz/future/FirsatAnaBoyut.json/FIRSAT';
      else if (sectionName === "FIRSAT")
        route = '/quiz/future/TehditAnaBoyut.json/TEHDİT';
      else if (sectionName === "TEHDİT")
        route = '/quiz/future/StratejiAnaBoyut.json/STRATEJİ';
      else if (sectionName === "STRATEJİ")
        route = '/quiz/future/TeknolojiAnaBoyut.json/TEKNOLOJİ';
      else if (sectionName === "TEKNOLOJİ")
        route = '/quiz/future/OrganizasyonAnaBoyut.json/ORGANİZASYON';
      else if (sectionName === "ORGANİZASYON")
        route = '/quizfinished/future';
      // route = '/quizstart/digitalization';
    } else if (quizType === "digitalization") {
      if (sectionName === "OPERASYON")
        route = '/quiz/digitalization/OrganizasyonAnaBoyut.json/ORGANİZASYON';
      else if (sectionName === "ORGANİZASYON")
        route = '/quiz/digitalization/StratejiAnaBoyut.json/STRATEJİ';
      else if (sectionName === "STRATEJİ")
        route = '/quiz/digitalization/TeknolojiAnaBoyut.json/TEKNOLOJİ';
      else if (sectionName === "TEKNOLOJİ")
        route = '/quizfinished/digitalization';
      // route = '/quizstart/sustainability';
    }

    const companyName = localStorage.getItem('companyName');
    try {
      const response = await fetch(`https://corexas-analysis-backend.onrender.com/api/progress/${encodeURIComponent(companyName)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('jwt'),
        },
        body: JSON.stringify({
          latestVisitedRoute: route,
        }),
      });
      if (!response.ok) {
        console.error('Failed to update progress.');
      }
    } catch (error) {
      console.error('Error updating progress:', error);
    }

    navigate(route);
  };

  const handlePreviousBigSection = () => {
    const sectionNames = {
      "sustainability": ["OPERASYON", "ORGANİZASYON", "STRATEJİ", "TEKNOLOJİ"],
      "startup": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON", "OPERASYON"],
      "future": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON"],
      "digitalization": ["OPERASYON", "ORGANİZASYON", "STRATEJİ", "TEKNOLOJİ"]
    };

    const jsonFileNames = {
      "sustainability": ["OperasyonAnaBoyut.json", "OrganizasyonAnaBoyut.json", "StratejiAnaBoyut.json", "TeknolojiAnaBoyut.json"],
      "startup": ["ProblemAnaBoyut.json", "IhtiyacAnaBoyut.json", "FirsatAnaBoyut.json", "TehditAnaBoyut.json", "StratejiAnaBoyut.json", "TeknolojiAnaBoyut.json", "OrganizasyonAnaBoyut.json", "OperasyonAnaBoyut.json"],
      "future": ["ProblemAnaBoyut.json", "IhtiyacAnaBoyut.json", "FirsatAnaBoyut.json", "TehditAnaBoyut.json", "StratejiAnaBoyut.json", "TeknolojiAnaBoyut.json", "OrganizasyonAnaBoyut.json"],
      "digitalization": ["OperasyonAnaBoyut.json", "OrganizasyonAnaBoyut.json", "StratejiAnaBoyut.json", "TeknolojiAnaBoyut.json"]
    };

    const currentQuizSections = sectionNames[quizType];

    const sectionIndex = currentQuizSections.indexOf(sectionName);

    if (sectionIndex > 0) {
      navigate(`/quiz/${quizType}/${jsonFileNames[quizType][sectionIndex - 1]}/${currentQuizSections[sectionIndex - 1]}`);

    } else console.log("Error, this button should not be active and this function should not be reachible.");
  };

  useEffect(() => {
    const sectionNames = {
      "sustainability": ["OPERASYON", "ORGANİZASYON", "STRATEJİ", "TEKNOLOJİ"],
      "startup": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON", "OPERASYON"],
      "future": ["PROBLEM", "İHTİYAÇ", "FIRSAT", "TEHDİT", "STRATEJİ", "TEKNOLOJİ", "ORGANİZASYON"],
      "digitalization": ["OPERASYON", "ORGANİZASYON", "STRATEJİ", "TEKNOLOJİ"]
    };

    const currentQuizSections = sectionNames[quizType];

    const bigSectionIndex = currentQuizSections.indexOf(sectionName);

    // console.log(bigSectionIndex > 0);
    if (bigSectionIndex > 0) {
      setIsFirstBigSection(false);
    } else setIsFirstBigSection(true);

    if (bigSectionIndex < 3) {
      setIsLastBigSection(false);
    } else {
      setIsLastBigSection(true);
    }
  }, [quizType, sectionName])

  const encodeKey = (key) => {
    return key.replace(/ /g, '_').replace(/\./g, '_dot_');
  };

  // useEffect(() => {
  //   if (Object.keys(answers).length === 0) return;
  //   if (currentQuestion) {
  //     const encodedKey = encodeKey(currentQuestion.question);
  //     console.log("Current Question: " + currentQuestion.question);
  //     console.log("Encoded Key: " + encodedKey);
  //     console.log("Current Answer: " + answers[encodedKey]);
  //     console.log("Answers: " + JSON.stringify(answers));
  //     setSliderValue(answers[encodedKey] || 30);
  //   }
  // }, [currentQuestionIndex, currentSectionIndex, answers, currentQuestion, sliderValue]);

  useEffect(() => {
    if (Object.keys(answers).length === 0) return; // Skip if no answers
    if (currentQuestion) {
      const encodedKey = encodeKey(currentQuestion.question); // Encode the key
      const currentValue = answers[encodedKey] || 30; // Retrieve the value or default to 30
      setSliderValue(currentValue); // Set slider value
    }
  }, [currentQuestionIndex, currentSectionIndex, answers, currentQuestion]); // Remove `sliderValue` from dependencies

  // useEffect(() => {
  //   if (Object.keys(answers).length === 0) return;
  //   if (currentQuestion) {
  //     console.log("Current Question: " + currentQuestion.question);
  //     console.log("Current Answer: " + answers[currentQuestion.question]);
  //     console.log("Answers: " + JSON.stringify(answers));
  //     setSliderValue(answers[currentQuestion.question] || 30);
  //   }
  // }, [currentQuestionIndex, currentSectionIndex, currentQuestion, answers]);

  const getVisibleSections = () => {
    if (quizData.length === 0) return [null, null, null];

    const left = currentSectionIndex > 0 ? quizData[currentSectionIndex - 1] : null;
    const middle = quizData[currentSectionIndex];
    const right = currentSectionIndex < quizData.length - 1 ? quizData[currentSectionIndex + 1] : null;

    return [left, middle, right];
  };

  useEffect(() => {
    if (quizData.length > 0) {
      if (currentSectionIndex >= quizData.length) {
        setCurrentSectionIndex(quizData.length - 1);
      }
      if (currentSectionIndex < 0) {
        setCurrentSectionIndex(0);
      }
      const sectionQuestions = quizData[currentSectionIndex]?.questions || [];
      if (currentQuestionIndex >= sectionQuestions.length) {
        setCurrentQuestionIndex(sectionQuestions.length - 1);
      }
      if (currentQuestionIndex < 0) {
        setCurrentQuestionIndex(0);
      }
    }
  }, [quizData, currentSectionIndex, currentQuestionIndex]);

  useEffect(() => {
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  }, [quizType, quizFile, sectionName]);

  const visibleSections = getVisibleSections();

  return (
    <div className={"quiz-page"} style={{ overflow: "hidden", paddingLeft: "20px", paddingRight: "20px", width: "calc(100vw - 40px)", height: "100vh", backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
      {quizData.length > 0 && (
        <div className='quiz-page-header'>
          <div style={{ display: "flex", flexDirection: "row", height: "15vh", width: "100vw" }}>
            <div className='quiz-page-header-left-section'>
              <div className="TextDiv" style={{ width: "calc(100% - 20px)", height: "40%", display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "10px" }}>
                <img alt="" src={XLeftWhite} style={{ width: "30px", aspectRatio: 1, cursor: "pointer" }} onClick={() => { navigate("/intro"); }} />
                <p style={{ margin: 0, fontWeight: "bold", fontSize: 30 }}> {sectionName} </p>
                <img alt="" src={XRightWhite} style={{ width: "30px", aspectRatio: 1, cursor: "pointer" }} onClick={() => { navigate("/intro"); }} />
              </div>
              <div className='linear-gradient' style={{ width: "100%", height: "3px" }} />
              <div style={{ display: "flex", flexDirection: "row", height: "60%", justifyContent: "space-between" }}>
                {!(currentSectionIndex === 0 && isFirstBigSection) &&
                  <img src={leftArrow} alt={""} onClick={handleJumpToPreviousSection} style={{ cursor: "pointer", width: "10%", height: "100%" }} />}
                {/* <button onClick={handleJumpToPreviousSection} style={{ width: "10%", height: "100%" }}>Previous Section</button>} */}
                {(currentSectionIndex === 0 && isFirstBigSection) &&
                  <div style={{ width: "10%", height: "100%" }} />}

                <div
                  className="section-names-container"
                  style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                >
                  {visibleSections.map((section, index) => {
                    const isActive = quizData.indexOf(section) === currentSectionIndex;

                    return section ? (
                      <p
                        key={`section-${index}`}
                        className={`section-name ${isActive ? 'active-section' : 'inactive-section'}`}
                        onClick={() => {
                          setCurrentSectionIndex(quizData.indexOf(section));
                          setCurrentQuestionIndex(0);
                        }}
                        style={{ flex: 1, textAlign: 'center', cursor: 'pointer' }}
                      >
                        {section.name}
                      </p>
                    ) : (
                      <div key={`spacer-${index}`} style={{ flex: 1 }} />
                    );
                  })}
                </div>
                {!(currentSectionIndex === quizData.length - 1 && currentQuestionIndex === currentSection.questions.length - 1 && isLastBigSection) &&
                  <img src={rightArrow} alt='' onClick={handleJumpToNextSection} style={{ cursor: "pointer", width: "10%", height: "100%" }} />}
                {(currentSectionIndex === quizData.length - 1 && currentQuestionIndex === currentSection.questions.length - 1 && isLastBigSection) &&
                  <div style={{ width: "10%", height: "100%" }} />}
              </div>
            </div>
            <div className='quiz-page-header-right-section center-items'>
              <div className="circle linear-gradient-rotated center-items" style={{ height: "90%", aspectRatio: 1 }}>
                <div className="circle center-items" style={{ height: "60%", aspectRatio: 1, backgroundColor: "white", position: "relative" }}>
                  <p style={{ position: "absolute", top: "-13%", fontSize: 10, fontWeight: "bold" }}>Hedef</p>
                  <input
                    style={{ position: "absolute", height: "50%", background: "transparent", border: "none", textAlign: "center", fontWeight: "bold", fontSize: 25 }}
                    type="text"
                    value={targetValues[encodeKey(currentQuestion?.question)] || ''}
                    onChange={(e) => handleTargetChange(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      }
      {
        quizData.length > 0 && (
          <div style={{ backgroundColor: "white", height: "calc(85vh - 20px - 13px - 20px - 10px)", marginTop: "15px", paddingTop: "10px" }}>
            {/* <div style={{ height: "20px", width: "100%" }} /> */}
            <div className="question">
              <div className="runnable-range-outer-div" style={{ position: "absolute", left: "-110px", top: "58vh", width: "54vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <input
                  style={{ rotate: "-90deg", height: "100%" }}
                  type="range"
                  id="range"
                  min="10"
                  max="50"
                  value={answers[encodeKey(currentQuestion?.question)] || 30}
                  onChange={(e) => handleChange(e.target.value)}
                />
                <span style={{
                  position: 'absolute',
                  bottom: `${(sliderValue - 25) / 0.85 - 4.5}vh`,
                  // transform: 'translate(-50%, -100%)',
                  backgroundColor: 'transparent',
                  color: '#110e3e',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  pointerEvents: "none",
                  fontWeight: "bold"
                }}>
                  {sliderValue / 10}
                </span>
              </div>
              <h3 style={{ paddingLeft: "2.5%", marginTop: "10px" }}>{currentQuestion?.question}</h3>
            </div>
            <div style={{ width: "100%", height: "2px", justifyContent: "center", display: "flex" }}>
              <div className='linear-gradient' style={{ width: "95%", height: "2px" }} />
            </div>

            <div className="answers-container" style={{ paddingLeft: "150px", paddingTop: "5.4vh", height: "50vh", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50vw" }}>
              {currentQuestion?.answers.map((answer, index) => (
                <div key={index} className="answer" style={{ height: "40px" }}>
                  {index === 4 && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> <div style={{ width: "40px", marginRight: "20px", height: "4px", backgroundColor: "#eafcff" }} /> <strong>1 - Var Olmayan</strong> </div>}
                  {index === 3 && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> <div style={{ width: "40px", marginRight: "20px", height: "4px", backgroundColor: "#c4eff7" }} /> <strong>2 - İlk Aşama</strong> </div>}
                  {index === 2 && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> <div style={{ width: "40px", marginRight: "20px", height: "4px", backgroundColor: "#6e9cb3" }} /> <strong>3 - Gelişmekte Olan</strong> </div>}
                  {index === 1 && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> <div style={{ width: "40px", marginRight: "20px", height: "4px", backgroundColor: "#043754" }} /> <strong>4 - Olgunlaşmış</strong> </div>}
                  {index === 0 && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}> <div style={{ width: "40px", marginRight: "20px", height: "4px", backgroundColor: "#110e3e" }} /> <strong>5 - İleri Düzey</strong> </div>}
                  {/* <br /> */}
                  <div style={{ paddingLeft: "60px" }} >{answer} </div>
                </div>
              ))}
            </div>


            <div className="navigation" style={{ width: "30%", position: "absolute", right: "5%", display: "flex", justifyContent: "space-between", marginTop: "2vh" }}>
              {(!(currentSectionIndex === 0 && currentQuestionIndex === 0) || !isFirstBigSection) && <img alt="" src={previousQuestion} style={{ width: "129px", height: "41px", cursor: "pointer" }} onMouseOver={e => (e.currentTarget.src = previousQuestionHover)} onMouseOut={e => (e.currentTarget.src = previousQuestion)} onClick={handlePrevious} />}
              {(currentSectionIndex === 0 && currentQuestionIndex === 0) && <div style={{ width: "129px", height: "41px" }} />}
              {!(currentSectionIndex === quizData.length - 1 && currentQuestionIndex === currentSection.questions.length - 1) && <img alt="" src={nextQuestion} style={{ width: "129px", height: "41px", cursor: "pointer" }} onMouseOver={e => (e.currentTarget.src = nextQuestionHover)} onMouseOut={e => (e.currentTarget.src = nextQuestion)} onClick={handleNext} />}
              {(currentSectionIndex === quizData.length - 1 && currentQuestionIndex === currentSection.questions.length - 1) && <img alt="" src={nextQuestion} style={{ width: "129px", height: "41px", cursor: "pointer" }} onMouseOver={e => (e.currentTarget.src = nextQuestionHover)} onMouseOut={e => (e.currentTarget.src = nextQuestion)} onClick={handleSave} />}
            </div>
          </ div>
        )
      }
    </div >
  );
}

export default QuizPage;